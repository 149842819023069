body {
    margin: 0;
    background: #eeeeee;
}

@font-face {
    font-family: 'Gilmer Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Regular'), url('../assets/fonts/gilmer-regular.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Bold'), url('../assets/fonts/gilmer-bold.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Heavy'), url('../assets/fonts/gilmer-heavy.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Light'), url('../assets/fonts/gilmer-light.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Outline';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Outline'), url('../assets/fonts/gilmer-outline.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Medium'), url('../assets/fonts/gilmer-medium.woff') format('woff');
}

html,
body,
#root,
.content-flex {
    height: 100%;
    overflow: hidden;
}

.content-flex {
    display: flex;
    overflow: hidden;
}

.content-width {
    flex: 1;
    overflow-y: auto;
    scroll-behavior: smooth;
}

a {
    color: white;
    text-decoration: none;
}

label {
    display: flex;
    align-items: center;
}

/* date pickers */

.MuiPickersCalendarHeader-dayLabel,
.DayPicker-Weekday {
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: bold !important;
}

.MuiPickersDay-daySelected {
    background-color: #4a90e2 !important;
}

/* placeholders */

input::placeholder,
textarea::placeholder {
    opacity: 1 !important;
    color: #333 !important;
    font-size: 12px !important;
    font-family: 'Gilmer Regular' !important;
}

em {
    color: #333 !important;
}

/* Emoji picker */
.emoji-picker-react {
    position: absolute !important;
    right: 0 !important;
    z-index: 5 !important;
}

.jodit-wysiwyg p:first-of-type {
    margin-top: 0 !important;
}

.MuiTableSortLabel-icon {
    opacity: 1 !important;
}

.MuiToolbar-regular {
    display: flex;
    align-items: center;
}

.MTableToolbar-spacer-41 {
    display: none;
}

.MTableToolbar-actions-42 {
    display: flex;
    align-items: center;
    width: 300px;
    flex-direction: row-reverse;
}

.MTableToolbar-actions-42 div div {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-around;
}

.MTableToolbar-actions-42 .MuiIconButton-label::after {
    margin-left: 3px;
    content: 'Options';
}

.MTableToolbar-actions-42 .MuiIconButton-label .MuiIcon-root {
    display: none;
}

.MTableToolbar-actions-42 .MuiIconButton-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 14px;
    font-size: 10px;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Gilmer Regular, Roboto;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    color: #3c3c3d;
    border: 1px solid rgba(60, 60, 61, 0.5);
    margin-left: 10px;
}

.custom-pagination-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.custom-pagination-wrapper .MuiTablePagination-actions {
    display: none;
}

.rrt-title {
    font-size: 14px !important;
    margin-bottom: 0 !important;
}
.rrt-text {
    font-size: 14px;
}
